<template>
  <div>
    <el-row>
      <el-col>
        <div>
          <el-form
            label-position="top"
            :label-width="labelWidthForm"
            :model="model"
            :rules="rules"
            @submit.native.prevent="doSubmit"
            class="form"
            ref="form"
            :disabled="isViewing"
          >
            <el-form-item
              :label="fields.name.label"
              :prop="fields.name.name"
              :required="fields.name.required"
            >
              <el-input
                v-model="model[fields.name.name]"
                :disabled="isDisabled"
              />
            </el-form-item>

            <el-form-item
              :label="fields.fakultaet.label"
              :required="fields.fakultaet.required"
              :prop="fields.fakultaet.name"
            >
              <app-fakultaet-autocomplete-input
                :fetchFn="fields.fakultaet.fetchFn"
                :mapperFn="fields.fakultaet.mapperFn"
                mode="single"
                v-model="model[fields.fakultaet.name]"
                :disabled="isDisabled"
              ></app-fakultaet-autocomplete-input>
            </el-form-item>

            <el-form-item
              :label="fields.arbeitszeit.label"
              :prop="fields.arbeitszeit.name"
              :required="fields.arbeitszeit.required"
            >
              <el-select
                v-model="model[fields.arbeitszeit.name]"
                :disabled="isDisabled"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.arbeitszeit.options"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="fields.von.label"
              :prop="fields.von.name"
              :required="fields.von.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                v-model="model[fields.von.name]"
                :disabled="isDisabled"
              ></el-date-picker>
            </el-form-item>

            <el-form-item
              :label="fields.bis.label"
              :prop="fields.bis.name"
              :required="fields.bis.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                v-model="model[fields.bis.name]"
                :disabled="isDisabled"
              ></el-date-picker>
            </el-form-item>

            <el-form-item
              :label="fields.preis.label"
              :prop="fields.preis.name"
              :required="fields.preis.required"
            >
              <app-money-input
                v-model="model[fields.preis.name]"
                :disabled="isDisabled"
              ></app-money-input>
            </el-form-item>

            <el-form-item
              :label="fields.verpflichtet.label"
              :prop="fields.verpflichtet.name"
              :required="fields.verpflichtet.required"
            >
              <el-select
                v-model="model[fields.verpflichtet.name]"
                :disabled="isDisabled"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.verpflichtet.options"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="fields.art.label"
              :prop="fields.art.name"
              :required="fields.art.required"
            >
              <el-select
                v-model="model[fields.art.name]"
                :disabled="isDisabled"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.art.options"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="fields.dokumente.label"
              :prop="fields.dokumente.name"
              :required="fields.dokumente.required"
            >
              <app-file-upload
                :max="fields.dokumente.max"
                :storage="fields.dokumente.storage"
                :formats="fields.dokumente.formats"
                :permissions="fields.dokumente.permissions"
                v-model="model[fields.dokumente.name]"
              ></app-file-upload>
            </el-form-item>
            <el-form-item>
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSubmit"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                  v-if="!isViewing"
                >
                  <app-i18n code="common.save"></app-i18n>
                </el-button>
                <el-button
                  :disabled="saveLoading"
                  @click="doCancel"
                  icon="el-icon-fa-close"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { WeiterbildungModel } from '@/modules/weiterbildung/weiterbildung-model';

const { fields } = WeiterbildungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.arbeitszeit,
  fields.name,
  fields.fakultaet,
  fields.von,
  fields.bis,
  fields.preis,
  fields.verpflichtet,
  fields.art,
  fields.mitarbeiter,
  fields.dokumente,
]);
export default {
  name: 'app-weiterbildung-form',

  props: ['isEditing', 'isDisabled', 'isViewing', 'record', 'saveLoading'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      pendingFiles: [],
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  watch: {
    'model.dokumente': {
      handler: function (newVal) {
        this.pendingFiles = newVal.filter((f) => f.new);
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel', this.pendingFiles);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
    async clearTableValidate() {
      await this.$refs.form.clearValidate();
    },
  },
};
</script>

<style></style>
