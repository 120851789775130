import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import user from '@/modules/user/user-module';
import settings from '@/modules/settings/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import vorlageDienstgeber from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-module';
import aenderung from '@/modules/aenderung/aenderung-module';
import auswertung from '@/modules/auswertung/auswertung-module';
import vorlageDienstnehmer from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-module';
import knowledgeBase from '@/modules/knowledge-base/knowledge-base-module';
import mandant from '@/modules/mandant/mandant-module';
import verwendungKostenstelle from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-module';
import mitarbeiter from '@/modules/mitarbeiter/mitarbeiter-module';
import statistikAustria from '@/modules/statistik-austria/statistik-austria-module';
import fakultaet from '@/modules/fakultaet/fakultaet-module';
import kostentraeger from '@/modules/kostentraeger/kostentraeger-module';
import vertragsart from '@/modules/vertragsart/vertragsart-module';
import kostenstelle from '@/modules/kostenstelle/kostenstelle-module';
import hauptkostenstelle from '@/modules/hauptkostenstelle/hauptkostenstelle-module';
import calendar from '@/modules/calendar/calendar-module';
import vertrag from '@/modules/vertrag/vertrag-module';
import vertragsergaenzung from '@/modules/vertragsergaenzung/vertragsergaenzung-module';
import vertragsaenderung from '@/modules/vertragsaenderung/vertragsaenderung-module';
import beendigung from '@/modules/beendigung/beendigung-module';
import bestaetigung from '@/modules/bestaetigung/bestaetigung-module';
import beruf from '@/modules/beruf/beruf-module';
import templateType from '@/modules/template-type/template-type-module';
import staatsbuergerschaft from '@/modules/staatsbuergerschaft/staatsbuergerschaft-module';
import einstufung from '@/modules/einstufung/einstufung-module';
import funktion from '@/modules/funktion/funktion-module';
import jahreskarte from '@/modules/jahreskarte/jahreskarte-module';
import weiterbildung from '@/modules/weiterbildung/weiterbildung-module';
import abmeldegrund from '@/modules/abmeldegrund/abmeldegrund-module';

const modules = {
  shared,
  home,
  einstufung,
  settings,
  auth,
  user,
  auditLog,
  aenderung,
  auswertung,
  layout,
  vorlageDienstgeber,
  vorlageDienstnehmer,
  knowledgeBase,
  mandant,
  mitarbeiter,
  statistikAustria,
  fakultaet,
  kostentraeger,
  verwendungKostenstelle,
  vertragsart,
  kostenstelle,
  hauptkostenstelle,
  calendar,
  vertrag,
  vertragsergaenzung,
  vertragsaenderung,
  beendigung,
  bestaetigung,
  beruf,
  funktion,
  abmeldegrund,
  templateType,
  staatsbuergerschaft,
  jahreskarte,
  weiterbildung,
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(directive.name, directive.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ modules: buildStores() });
  }

  return store;
};

export { setupComponentsFiltersDirectivesAndMixins, routerAsync, storeAsync };

// end - boilerplate code
