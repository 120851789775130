<template>
  <div>
    <el-row>
      <el-col>
        <div>
          <el-form
            label-position="top"
            :label-width="labelWidthForm"
            :model="model"
            :rules="rules"
            @submit.native.prevent="doSubmit"
            class="form"
            ref="form"
            :disabled="isViewing"
          >
            <el-form-item
              :label="fields.nummer.label"
              :prop="fields.nummer.name"
              :required="fields.nummer.required"
            >
              <el-input
                v-model="model[fields.nummer.name]"
                :disabled="isDisabled"
              />
            </el-form-item>

            <el-form-item
              :label="fields.zahlung.label"
              :prop="fields.zahlung.name"
              :required="fields.zahlung.required"
            >
              <el-select
                v-model="model[fields.zahlung.name]"
                :disabled="isDisabled"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.zahlung.options"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="fields.von.label"
              :prop="fields.von.name"
              :required="fields.von.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                v-model="model[fields.von.name]"
                :disabled="isDisabled"
              ></el-date-picker>
            </el-form-item>

            <el-form-item
              :label="fields.bis.label"
              :prop="fields.bis.name"
              :required="fields.bis.required"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                placeholder
                v-model="model[fields.bis.name]"
                :disabled="isDisabled"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              :label="fields.rechnung.label"
              :prop="fields.rechnung.name"
              :required="fields.rechnung.required"
            >
              <app-file-upload
                :max="fields.rechnung.max"
                :storage="fields.rechnung.storage"
                :formats="fields.rechnung.formats"
                :permissions="fields.rechnung.permissions"
                v-model="model[fields.rechnung.name]"
              ></app-file-upload>
            </el-form-item>
            <el-form-item>
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSubmit"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                  v-if="!isViewing"
                >
                  <app-i18n code="common.save"></app-i18n>
                </el-button>
                <el-button
                  :disabled="saveLoading"
                  @click="doCancel"
                  icon="el-icon-fa-close"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { JahreskarteModel } from '@/modules/jahreskarte/jahreskarte-model';

const { fields } = JahreskarteModel;
const formSchema = new FormSchema([
  fields.id,
  fields.zahlung,
  fields.nummer,
  fields.von,
  fields.bis,
  fields.mitarbeiter,
  fields.rechnung,
]);
export default {
  name: 'app-jahreskarte-form',

  props: ['isEditing', 'isDisabled', 'isViewing', 'record', 'saveLoading'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      pendingFiles: [],
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  watch: {
    'model.rechnung': {
      handler: function (newVal) {
        this.pendingFiles = newVal.filter((f) => f.new);
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel', this.pendingFiles);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
    async clearTableValidate() {
      await this.$refs.form.clearValidate();
    },
  },
};
</script>

<style></style>
