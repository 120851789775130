import weiterbildungListStore from '@/modules/weiterbildung/weiterbildung-list-store';
import weiterbildungFormStore from '@/modules/weiterbildung/weiterbildung-form-store';
import weiterbildungDestroyStore from '@/modules/weiterbildung/weiterbildung-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: weiterbildungDestroyStore,
    form: weiterbildungFormStore,
    list: weiterbildungListStore,
  },
};
