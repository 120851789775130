import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import Storage from '@/security/storage';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import { FakultaetField } from '@/modules/fakultaet/fakultaet-field';
import MoneyField from '@/shared/fields/money-field';

function label(name) {
  return i18n(`entities.weiterbildung.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.weiterbildung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    max: 254,
  }),
  fakultaet: FakultaetField.relationToOne('fakultaet', label('fakultaet'), {
    required: true,
  }),
  arbeitszeit: new EnumeratorField(
    'arbeitszeit',
    label('arbeitszeit'),
    [
      {
        id: 'ja',
        label: enumeratorLabel('arbeitszeit', 'ja'),
      },
      {
        id: 'nein',
        label: enumeratorLabel('arbeitszeit', 'nein'),
      },
    ],
    {
      required: true,
    },
  ),
  von: new DateField('von', label('von'), {
    required: true,
  }),
  bis: new DateField('bis', label('bis'), {
    required: true,
  }),
  preis: new MoneyField('preis', label('preis'), {
    required: true,
    min: 0,
    max: 10000000,
    scale: 2,
  }),
  verpflichtet: new EnumeratorField(
    'verpflichtet',
    label('verpflichtet'),
    [
      {
        id: 'ja',
        label: enumeratorLabel('verpflichtet', 'ja'),
      },
      {
        id: 'nein',
        label: enumeratorLabel('verpflichtet', 'nein'),
      },
    ],
    {
      required: true,
    },
  ),
  art: new EnumeratorField(
    'art',
    label('art'),
    [
      {
        id: 'externeWeiterbildung',
        label: enumeratorLabel('art', 'externeWeiterbildung'),
      },
      {
        id: 'interneSchulungen',
        label: enumeratorLabel('art', 'interneSchulungen'),
      },
      {
        id: 'internePersonalentwicklung',
        label: enumeratorLabel('art', 'internePersonalentwicklung'),
      },
      {
        id: 'arbeitszeitUnterweisung',
        label: enumeratorLabel('art', 'arbeitszeitUnterweisung'),
      },
    ],
    {
      required: true,
    },
  ),
  dokumente: new FilesField(
    'dokumente',
    label('dokumente'),
    Storage.values.weiterbildungDokumente,
    Permissions.getFilePermissions(
      'weiterbildungFileFields',
      'weiterbildungDokumente',
    ),
    {
      required: true,
      max: 20 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  mitarbeiter: new StringField('mitarbeiter', label('mitarbeiter')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class WeiterbildungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
