<template>
  <div>
    <div style="padding-bottom: 60px">
      <el-row>
        <div class="anstellung">
          <el-row :gutter="20">
            <el-col :span="24" :offset="0">
              <app-weiterbildung-list-table
                @doEdit="doEdit"
                @doOpen="doOpen"
                @doExtend="doExtend"
                @doDelete="doDestroyWithConfirm"
              />
            </el-col>
          </el-row>
        </div>

        <div>
          <app-weiterbildung-form-modal
            :visible="dialogWeiterbildungFormVisible"
            :record="row"
            :isExtending="isExtending"
            :isViewing="isViewing"
            :mitarbeiter="mitarbeiter"
            v-if="dialogWeiterbildungFormVisible"
            @close="doCloseDlg"
          />
        </div>
        <div class="form-buttons">
          <el-button
            v-if="isCreateButtonVisible"
            v-on:click="
              dialogWeiterbildungFormVisible = !dialogWeiterbildungFormVisible
            "
            plain
            type="info"
          >
            <app-i18n code="common.add"></app-i18n>
          </el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import WeiterbildungFormModal from '@/modules/weiterbildung/components/weiterbildung-form-modal.vue';
import WeiterbildungListTable from '@/modules/weiterbildung/components/weiterbildung-list-table.vue';
import { WeiterbildungPermissions } from '@/modules/weiterbildung/weiterbildung-permissions';

import { i18n } from '@/i18n';

export default {
  name: 'app-weiterbildung-main',
  components: {
    [WeiterbildungFormModal.name]: WeiterbildungFormModal,
    [WeiterbildungListTable.name]: WeiterbildungListTable,
  },
  data() {
    return {
      row: null,
      dialogWeiterbildungFormVisible: false,
      isExtending: false,
      isViewing: false,
    };
  },
  async mounted() {
    const filter = {
      mitarbeiter: this.mitarbeiter,
    };

    await this.doFetch({
      filter,
    });
  },
  computed: {
    ...mapGetters({
      mitarbeiter: 'mitarbeiter/form/id',
      currentUser: 'auth/currentUser',
    }),
    isCreateButtonVisible() {
      if (this.hasPermissionToCreate && this.mitarbeiter != null) {
        return true;
      }
      return false;
    },
    hasPermissionToCreate() {
      return new WeiterbildungPermissions(this.currentUser).create;
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'weiterbildung/list/doFetch',
      doDestroy: 'weiterbildung/destroy/doDestroy',
    }),

    async doDelete() {},

    doCloseDlg() {
      this.row = null;
      this.isExtending = false;
      this.isViewing = false;
      this.dialogWeiterbildungFormVisible = false;
    },

    async doEdit(row) {
      this.row = row;
      this.dialogWeiterbildungFormVisible = true;
    },

    async doOpen(row) {
      this.row = row;
      this.isViewing = true;
      this.dialogWeiterbildungFormVisible = true;
    },

    async doExtend(row) {
      let newRow = { ...row };

      this.row = newRow;
      this.row.id = null;
      this.row.von = Vue.DateHelper.addDays(newRow.bis, 1);
      this.row.bis = null;
      this.isExtending = true;
      this.dialogWeiterbildungFormVisible = true;
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>
