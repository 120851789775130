import { JahreskarteService } from '@/modules/jahreskarte/jahreskarte-service';
import jahreskarteExporterFields from '@/modules/jahreskarte/jahreskarte-list-exporter-fields';
import Errors from '@/shared/error/errors';

import Exporter from '@/shared/exporter/exporter';

export default {
  namespaced: true,

  state: {
    rows: [],
    papamonat: [],
    count: 0,
    loading: false,
    loaded: false,
    filter: {},
    pagination: {},
    sorter: {},
    selectedRows: [],
    table: null,
  },

  getters: {
    loading: (state) => state.loading,
    loaded: (state) => state.loaded,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,
    selectedRows: (state) => {
      return state.selectedRows || [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.loaded = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    SELECTED_ROWS_SET(state, payload) {
      state.selectedRows = payload;
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
      state.loaded = true;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.loaded = false;
      state.rows = [];
      state.count = 0;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doSetSelectedRows({ commit }, rows) {
      commit('SELECTED_ROWS_SET', rows);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doFetch({ commit, getters }, { filter } = {}) {
      try {
        commit('FETCH_STARTED', { filter });

        const response = await JahreskarteService.list(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doExport({ getters }, { filter } = {}) {
      try {
        const response = await JahreskarteService.list(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset,
          true,
        );
        new Exporter(
          jahreskarteExporterFields,
          'jahreskarte',
        ).transformAndExportAsExcelFile(response.rows);
      } catch (error) {
        Errors.handle(error);
      }
    },
  },
};
