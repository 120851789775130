import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class WeiterbildungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);
    this.read = permissionChecker.match(Permissions.values.weiterbildungRead);
    this.create = permissionChecker.match(
      Permissions.values.weiterbildungCreate,
    );
    this.edit = permissionChecker.match(Permissions.values.weiterbildungEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.weiterbildungDestroy,
    );
  }
}
