import { WeiterbildungExportModel } from '@/modules/weiterbildung/weiterbildung-export-model';

const { fields } = WeiterbildungExportModel;

export default [
  fields.mitarbeiterNr,
  fields.anrede,
  fields.titel,
  fields.nachname,
  fields.vorname,
  fields.vollNameExport,
  fields.svNummer,
  fields.name,
  fields.fakultaet,
  fields.arbeitszeit,
  fields.von,
  fields.bis,
  fields.preis,
  fields.verpflichtet,
  fields.art,
];
