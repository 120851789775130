import authAxios from '@/shared/axios/auth-axios';

export class WeiterbildungService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/weiterbildung/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/weiterbildung`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/weiterbildung`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/weiterbildung/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset, isExport) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
    };

    const response = await authAxios.get(`/weiterbildung`, {
      params,
    });

    return response.data;
  }
}
