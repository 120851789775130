<template>
  <div class="anstellung">
    <el-form
      :model="model"
      ref="form"
      :rules="model.rules"
      :disabled="isDisabledInternal"
    >
      <el-table
        :data="model.tableData"
        ref="table"
        style="width: 100%"
        max-height="450"
        v-if="loaded"
      >
        <el-table-column width="15">
          <template v-slot="scope">
            <el-badge is-dot :type="getStatusTagType(scope.row)"></el-badge>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          :xl="8"
          :lg="14"
          :md="12"
          :sm="24"
          :label="fields.kostenstelle.label"
          :prop="fields.kostenstelle.name"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.kostenstelle.required"
              :prop="'tableData.' + scope.$index + '.kostenstelle'"
              :rules="model.rules.kostenstelle"
            >
              <app-kostenstelle-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.kostenstelle.fetchFn"
                :mapperFn="fields.kostenstelle.mapperFn"
                mode="single"
                v-model="scope.row[fields.kostenstelle.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-kostenstelle-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          :label="fields.hauptkostenstelle.label"
          :prop="fields.hauptkostenstelle.name"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.hauptkostenstelle.required"
              :prop="'tableData.' + scope.$index + '.hauptkostenstelle'"
              :rules="model.rules.hauptkostenstelle"
            >
              <app-hauptkostenstelle-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.hauptkostenstelle.fetchFn"
                :mapperFn="fields.hauptkostenstelle.mapperFn"
                mode="single"
                v-model="scope.row[fields.hauptkostenstelle.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-hauptkostenstelle-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column
          :lg="13"
          :md="16"
          :sm="24"
          :label="fields.kostentraeger.label"
          :prop="fields.kostentraeger.name"
          width="100"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.kostentraeger.required"
              :prop="'tableData.' + scope.$index + '.kostentraeger'"
              :rules="model.rules.kostentraeger"
            >
              <app-kostentraeger-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.kostentraeger.fetchFn"
                :mapperFn="fields.kostentraeger.mapperFn"
                mode="single"
                v-model="scope.row[fields.kostentraeger.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-kostentraeger-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.prozent.label"
          :prop="fields.prozent.name"
          width="80"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.prozent.required"
              :prop="'tableData.' + scope.$index + '.prozent'"
              :rules="model.rules.prozent"
            >
              <el-input-number
                :disabled="true"
                :controls="false"
                :precision="fields.prozent.scale"
                :max="fields.prozent.max"
                :min="fields.prozent.min"
                v-model="scope.row[fields.prozent.name]"
              ></el-input-number>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column
          :label="fields.von.label"
          :prop="fields.von.name"
          width="135"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.von.required"
              :prop="'tableData.' + scope.$index + '.von'"
              :rules="model.rules.von"
            >
              <el-date-picker
                @change="handleTableChange(scope.row)"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="scope.row[fields.von.name]"
                :picker-options="disabledVonDates()"
                :disabled="rowDisabled(scope.row)"
              ></el-date-picker>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.bis.label"
          :prop="fields.bis.name"
          width="135"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.bis.required"
              :prop="'tableData.' + scope.$index + '.bis'"
              :rules="model.rules.bis"
            >
              <el-date-picker
                @change="handleTableChange(scope.row)"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="scope.row[fields.bis.name]"
                :picker-options="disabledBisDates(scope.row)"
                :disabled="rowDisabled(scope.row, true)"
              ></el-date-picker>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.wochenstunden.label"
          :prop="fields.wochenstunden.name"
          width="80"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.wochenstunden.required"
              :prop="'tableData.' + scope.$index + '.wochenstunden'"
              :rules="model.rules.wochenstunden"
            >
              <app-money-input
                v-model="scope.row[fields.wochenstunden.name]"
                @change="handleTableChange(scope.row)"
                :disabled="rowDisabled(scope.row)"
              ></app-money-input>
              <!--    <el-input-number
                @change="handleTableChange(scope.row)"
                :controls="false"
                :max="fields.wochenstunden.max"
                :min="fields.wochenstunden.min"
                :precision="fields.wochenstunden.scale"
                v-model="scope.row[fields.wochenstunden.name]"
                :disabled="rowDisabled(scope.row.status)"
              ></el-input-number> -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.brutto.label"
          :prop="fields.brutto.name"
          width="120"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.brutto.required"
              :prop="'tableData.' + scope.$index + '.brutto'"
              :rules="model.rules.brutto"
            >
              <app-money-input
                v-model="scope.row[fields.brutto.name]"
                @change="handleTableChange(scope.row)"
                :disabled="rowDisabled(scope.row)"
              ></app-money-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-gesamtkosten="true"
          :label="fields.gesamtkosten.label"
          :prop="fields.gesamtkosten.name"
          width="120"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.gesamtkosten.required"
              :prop="'tableData.' + scope.$index + '.gesamtkosten'"
              :rules="model.rules.gesamtkosten"
            >
              <app-money-input
                v-model="scope.row[fields.gesamtkosten.name]"
                :readonly="true"
                :disabled="rowDisabled(scope.row)"
              ></app-money-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.fakultaet.label"
          :prop="fields.fakultaet.name"
          width="120"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.fakultaet.required"
              :prop="'tableData.' + scope.$index + '.fakultaet'"
              :rules="model.rules.fakultaet"
            >
              <app-fakultaet-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.fakultaet.fetchFn"
                :mapperFn="fields.fakultaet.mapperFn"
                mode="single"
                v-model="scope.row[fields.fakultaet.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-fakultaet-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column
          :label="fields.verwendungKostenstelle.label"
          :prop="fields.verwendungKostenstelle.name"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.verwendungKostenstelle.required"
              :prop="'tableData.' + scope.$index + '.verwendungKostenstelle'"
              :rules="model.rules.verwendungKostenstelle"
            >
              <app-verwendung-kostenstelle-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.verwendungKostenstelle.fetchFn"
                :mapperFn="fields.verwendungKostenstelle.mapperFn"
                mode="single"
                v-model="scope.row[fields.verwendungKostenstelle.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-verwendung-kostenstelle-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          :label="fields.einstufung.label"
          :prop="fields.einstufung.name"
        >
          <template v-slot="scope">
            <el-form-item
              :key="addCount"
              :required="fields.einstufung.required"
              :prop="'tableData.' + scope.$index + '.einstufung'"
              :rules="model.rules.einstufung"
            >
              <app-einstufung-autocomplete-input
                @input="castAndUpdateGrundgehalt()"
                :fetchFn="fields.einstufung.fetchFn"
                :mapperFn="fields.einstufung.mapperFn"
                mode="single"
                v-model="scope.row[fields.einstufung.name]"
                :disabled="rowDisabled(scope.row)"
              ></app-einstufung-autocomplete-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="Aktion" width="100" v-if="!isInactive">
          <template v-slot="scope">
            <div>
              <template v-if="!scope.row.edit">
                <el-button
                  @click="deleteRow(scope.$index, scope.row)"
                  type="danger"
                  size="small"
                  circle
                  plain
                  icon="el-icon-delete"
                  v-if="hasPermissionToDeleteKostenstelle || scope.row.notSaved"
                ></el-button>
                <el-button
                  @click="setEditable(scope.$index)"
                  type="warning"
                  size="small"
                  circle
                  plain
                  icon="el-icon-edit"
                  v-if="
                    hasPermissionToEditKostenstelle && rowDisabled(scope.row)
                  "
                ></el-button>
              </template>
              <span v-else>
                <el-button
                  @click="scope.row.edit = !scope.row.edit"
                  type="success"
                  size="small"
                  circle
                  plain
                  icon="el-icon-check"
                ></el-button>
                <el-button
                  @click="cancelEdit(scope.$index)"
                  type="warning"
                  size="small"
                  circle
                  plain
                  icon="el-icon-close"
                ></el-button>
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="form-buttons">
      <el-button
        @click="addRow"
        plain
        type="info"
        v-if="hasPermissionToCreateKostenstelle && !isInactive"
      >
        <app-i18n code="common.addRow"></app-i18n>
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { i18n } from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { GrundgehaltModel } from '@/modules/grundgehalt/grundgehalt-model';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import CalculationHelper from '@/shared/helpers/calculationHelper';

const { fields } = GrundgehaltModel;
const formSchema = new FormSchema([
  fields.id,
  fields.kostenstelle,
  fields.hauptkostenstelle,
  fields.kostentraeger,
  fields.prozent,
  fields.von,
  fields.bis,
  fields.wochenstunden,
  fields.brutto,
  fields.gesamtkosten,
  fields.fakultaet,
  fields.einstufung,
  fields.verwendungKostenstelle,
  fields.new,
  fields.status,
  fields.internalId,
  fields.anstellung,
]);

export default {
  name: 'app-grundgehalt-component',
  props: [
    'record',
    'anstellung',
    'gesamtbrutto',
    'gesamtkosten',
    'modal',
    'hasPermissionToDeleteKostenstelle',
    'hasPermissionToEditKostenstelle',
    'hasPermissionToCreateKostenstelle',
    'isDisabled',
  ],
  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      loaded: false,
      tableData: [],
      addCount: 0,
    };
  },
  created() {
    this.model.tableData = [];
    if (this.record) {
      this.model.tableData = this.record.map((f) => {
        return formSchema.initialValues(f || {});
      });
    }
    this.model.tableData = this.model.tableData.map((f) => {
      this.$set(f, 'edit', false);
      return f;
    });

    this.addCount = this.model.tableData.length;
    this.model.rules = this.rules;
    this.loaded = true;
    if (!this.modal) {
      this.castAndUpdateGrundgehalt();
    }
    if (!this.isEditing) {
      this.addRow();
    }
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      isInactiveMitarbeiter: 'mitarbeiter/form/isInactive',
      isInMutterschutz: 'mitarbeiter/form/isInMutterschutz',
      isInMutterkarenz: 'mitarbeiter/form/isInMutterkarenz',
      isInBildungskarenz: 'mitarbeiter/form/isInBildungskarenz',
      isInPapamonat: 'mitarbeiter/form/isInPapamonat',
      isInPlan: 'mitarbeiter/form/isInPlan',
      isInactive: 'mitarbeiter/form/isInactive',
      viewMode: 'mitarbeiter/form/viewMode',
      eintrittsdatum: 'mitarbeiter/form/eintrittsdatum',
      austrittsdatum: 'mitarbeiter/form/austrittsdatum',
      gesamtbruttoGehaltszulage:
        'mitarbeiter/anstellung/gesamtbruttoGehaltszulage',
      reActivationStarted: 'mitarbeiter/form/reActivationStarted',
      svWerteActive: 'settings/svWerteActive',
    }),
    fields() {
      return fields;
    },
    today() {
      return Vue.DateHelper.noTimeDate(new Date());
    },
    gesamtbruttoGehaltszulageChanged() {
      return this.gesamtbruttoGehaltszulage;
    },
    isEditing() {
      return !!this.record;
    },
    isDisabledInternal() {
      return (
        (this.isInactiveMitarbeiter || this.viewMode || !!this.modal) &&
        this.isDisabled
      );
    },
  },
  watch: {
    gesamtbruttoGehaltszulageChanged: {
      handler: function () {
        if (this.loaded) {
          this.recalculateGesamtbrutto();
        }
      },
    },
    gesamtkosten: {
      handler: function () {
        if (this.loaded) {
          this.recalculateTableData();
        }
      },
    },
    reActivationStarted: {
      handler: function (value) {
        if (value) {
          this.addRow();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      doUpdateGrundgehalt: 'mitarbeiter/form/doSetGrundgehalt',
      doSetGesamtbruttoGrundgehalt:
        'mitarbeiter/anstellung/doSetGesamtbruttoGrundgehalt',
    }),

    async validateForm() {
      return this.$refs.form.validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    async validateTable() {
      await this.$refs.form.validate();
    },
    async clearTableValidate() {
      await this.$refs.form.clearValidate();
    },

    async handleTableChange(row) {
      if (row.wochenstunden) {
        this.handleWochenstundenChange();
      }

      if (row.brutto) {
        await this.recalculateGesamtbrutto();

        /* this.$emit('gesamtbruttoChange'); */
        this.castAndUpdateGrundgehalt();
      }
    },

    async handleWochenstundenChange() {
      const gesamtwochenstunden = parseFloat(
        new CalculationHelper(this.svWerteActive)
          .sumArrayObjectField(this.model.tableData, 'wochenstunden')
          .toFixed(2),
        10,
      );
      this.$emit('woStdChange', gesamtwochenstunden);
    },

    async recalculateGesamtbrutto() {
      const gesamtbrutto = new CalculationHelper(
        this.svWerteActive,
      ).getGesamtbrutto(this.model.tableData, 'brutto');

      await this.doSetGesamtbruttoGrundgehalt(gesamtbrutto);
    },

    async recalculateTableData() {
      this.model.tableData.map((f) => {
        const today = Vue.DateHelper.noTimeDate(new Date());
        if (
          f.bis &&
          !Vue.DateHelper.areDatesInSameMonth(today, f.bis) &&
          (Vue.DateHelper.isInPast(f.bis) || Vue.DateHelper.isToday(f.bis))
        ) {
          f.prozent = 0;
          f.gesamtkosten = 0;
        }

        if (this.gesamtbrutto === 0 || this.gesamtkosten === 0) {
          f.prozent = 0;
          f.gesamtkosten = 0;
          return f;
        }
        if (f.brutto) {
          const calculatedBrutto = new CalculationHelper(
            this.svWerteActive,
          ).getKstBrutto(f, 'brutto');
          f.prozent = parseFloat(
            ((100 / this.gesamtbrutto) * calculatedBrutto).toFixed(2),
            10,
          );
          f.gesamtkosten = parseFloat(
            ((this.gesamtkosten * f.prozent) / 100).toFixed(2),
            10,
          );
        }

        return f;
      });
      this.castAndUpdateGrundgehalt();
      this.handleWochenstundenChange();
    },

    disabledBisDates(row) {
      return {
        disabledDate: (date) => {
          return date < Vue.DateHelper.noTimeDate(row.von);
        },
      };
    },
    disabledVonDates() {
      return {
        disabledDate: (date) => {
          if (this.record == undefined) {
            return date < this.eintrittsdatum;
          }
        },
      };
    },
    rowDisabled(row, isBisCol) {
      if (!this.hasPermissionToEditKostenstelle) {
        if (!(row.new || this.isInPlan)) {
          if (!isBisCol) {
            return true;
          }
        }
      }
      let ret;
      if (
        this.isInactiveMitarbeiter ||
        this.isInMutterschutz ||
        this.isInMutterkarenz ||
        this.isInBildungskarenz ||
        this.isInPapamonat ||
        this.viewMode ||
        this.modal
      ) {
        ret = true;
      }
      ret = row.status === 'inactive';

      return ret && !row.edit && this.isDisabled;
    },

    setEditable(index) {
      this.model.tableData[index].edit = true;
      if (!this.model.tableData[index].tmpRowData) {
        this.model.tableData[index].tmpRowData = cloneDeep(
          this.model.tableData[index],
        );
      }

      this.model.tableData[index].edit = true;
    },

    restoreRow(row, rowIdx) {
      const tt = this.record.find((f) => f.id === row.id);
      this.model.tableData[rowIdx] = formSchema.initialValues(tt);

      this.model.tableData[rowIdx].edit = false;
    },

    cancelEdit(index) {
      this.model.tableData[index].edit = false;
      this.model.tableData[index] = cloneDeep(
        this.model.tableData[index].tmpRowData,
      );
      this.model.tableData[index].edit = false;
    },

    confirmEdit(index) {
      this.model.tableData[index].edit = false;
    },

    async deleteRow(index) {
      await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
        confirmButtonText: i18n('common.yes'),
        cancelButtonText: i18n('common.no'),
        type: 'warning',
      });

      this.model.tableData.splice(index, 1);
      if (this.addCount > 0) --this.addCount;
      this.recalculateGesamtbrutto();
      this.handleWochenstundenChange();
      this.castAndUpdateGrundgehalt();
    },

    castAndUpdateGrundgehalt() {
      if (this.model.tableData) {
        let tableData = [];
        this.model.tableData.map((f) => {
          let row = formSchema.cast(f);
          row.anstellung = this.anstellung;
          tableData.push(row);
        });
        this.doUpdateGrundgehalt(tableData);
      }
    },
    async addRow() {
      let newRow = formSchema.initialValues({});
      newRow.id = uuid();
      newRow.new = true;
      newRow.notSaved = true;
      newRow.anstellung = this.anstellung;

      this.model.tableData.length === 0
        ? this.model.tableData.push(newRow)
        : this.model.tableData.unshift(newRow);

      ++this.addCount;
    },

    getStatusTagType(row) {
      if (row.status === 'active') {
        return 'success';
      }
      if (row.status === 'inactive') {
        return 'danger';
      }
      if (row.new) {
        return 'info';
      }
      if (!row.status && !row.new) {
        return 'warning';
      }
      return '';
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
.el-table__row .el-input .el-input__inner {
  border-style: none;
}
.hover-row .el-input .el-input__inner {
  border-style: solid;
}
* >>> tr .el-form-item__error {
  display: none !important;
}
</style>
