import StringArrayField from '@/shared/fields/string-array-field';
import * as yup from 'yup';
import Roles from '@/security/roles';
import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import BooleanField from '@/shared/fields/boolean-field';
import ImagesField from '@/shared/fields/images-field';
import DateTimeField from '@/shared/fields/date-time-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import GenericField from '@/shared/fields/generic-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { GenericModel } from '@/shared/model/generic-model';
import Storage from '@/security/storage';
import EmailsField from '@/shared/fields/emails-field';

function enumeratorLabel(name, value) {
  return i18n(`user.enumerators.${name}.${value}`);
}

class RolesField extends StringArrayField {
  constructor(name, label, config) {
    super(name, label, config);

    this.options = Roles.selectOptions;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((values) =>
        values ? values.map((value) => Roles.labelOf(value)).join(' ') : null,
      );
  }
}
function label(name) {
  return i18n(`user.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  anrede: new EnumeratorField(
    'anrede',
    label('anrede'),
    [
      {
        id: 'Frau',
        label: i18n('user.anrede.frau'),
      },
      {
        id: 'Herr',
        label: i18n('user.anrede.herr'),
      },
      {
        id: 'keineAngabe',
        label: enumeratorLabel('anrede', 'keineAngabe'),
      },
    ],
    { required: true },
  ),

  academicTitle: new StringField('academicTitle', label('academicTitle'), {}),
  firstName: new StringField('firstName', label('firstName'), {
    required: true,
    max: 80,
  }),

  lastName: new StringField('lastName', label('lastName'), {
    required: true,
    max: 175,
  }),
  email: new StringField('email', label('email'), {
    required: true,
    matches:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    max: 254,
  }),
  password: new StringField('password', label('password'), {
    required: true,
  }),
  oldPassword: new StringField('oldPassword', label('oldPassword'), {
    required: true,
  }),
  newPassword: new StringField('newPassword', label('newPassword'), {
    required: true,
  }),
  newPasswordConfirmation: new StringField(
    'newPasswordConfirmation',
    label('newPasswordConfirmation'),
    {
      required: true,
    },
  ),
  fullName: new StringField('fullName', label('fullName')),
  role: new EnumeratorField('role', label('role'), Roles.selectOptions),
  rememberMe: new BooleanField('rememberMe', label('rememberMe')),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'enabled',
      label: i18n('user.status.enabled'),
    },
    {
      id: 'disabled',
      label: i18n('user.status.disabled'),
    },
  ]),

  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    required: true,
    matches: /^[0-9]/,
    max: 24,
  }),
  avatars: new ImagesField(
    'avatars',
    label('avatars'),
    Storage.values.userProfileAvatar,
    {
      max: 1,
    },
  ),
  emails: new EmailsField('emails', label('emails'), {
    required: true,
  }),
  rolesRequired: new RolesField('roles', label('roles'), {
    required: true,
  }),
  roles: new RolesField('roles', label('roles')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  roleUser: new GenericField('roleUser', label('roleUser')),
};

export class UserModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
